.Pages {
  display: grid;
  gap: 0.4rem;
  padding: 0.4rem;
  margin: 0;
  grid-template-columns: repeat(auto-fill, 180px);
}

.Wrapper {
  position: relative;
  list-style: none;
  width: 180px;
  /* margin-bottom: 0.5rem; */
}

.Wrapper.active .Page {
  background-image: none !important;
  /* filter: grayscale(100%); */
  background-color: #e6e6e6;
  /* display: none; */
}

.Wrapper.clone .Page {
  transform: translate3d(10px, 10px, 0) scale(1.025);
  animation: pop 150ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 6px 0 rgba(34, 33, 81, 0.3);
  cursor: grabbing;
  /* opacity: 0.9; */
}
.Wrapper:hover .Remove {
  visibility: visible;
}

.Wrapper.insertBefore::before {
  content: "";
  position: absolute;
  background-color: #4c9ffe;
  top: 0;
  bottom: 0;
  left: -5px;
  width: 4px;
}

.Wrapper.insertAfter::after {
  content: "";
  position: absolute;
  background-color: #4c9ffe;
  top: 0;
  bottom: 0;
  right: -5px;
  width: 4px;
}

.Page {
  position: relative;
  display: block;
  width: 100%;
  height: 150px;
  background-color: #faffff;
  background-size: cover;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(34, 33, 81, 0.15);
  outline: none;
  appearance: none;
  border: none;
  touch-action: none;
  /* cursor: zoom-in; */
}

.Remove {
  display: flex;
  visibility: hidden;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  padding: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.Remove:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.Remove:active {
  background-color: rgba(255, 70, 70, 0.9);
}
.Remove svg {
  fill: #fff;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pop {
  0% {
    transform: translate3d(0px, 0px, 0) scale(1);
  }
  100% {
    transform: translate3d(10px, 10px, 0) scale(1.025);
  }
}

/* .scaleAnimationIn {
  animation: scaleAnimationIn 1s;
   animation-delay: 1s;
  animation-fill-mode: both; 
} */

/* @keyframes scaleAnimationIn {
  0% {
    transform: scale(1, 0);
    animation-timing-function: ease-out;
  }
  100% {
    transform: scale(1, 1);
  }
} */

/* 
Wrapper
    active
    insertBefore
    insertAfter
    clone

Page
Remove
PageNumber
 */
