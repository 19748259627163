@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#root {
  height: 100%;
}

:root {
  --auto-grid-min-size: 12rem;
  --auto-grid-min-s-size: 9rem;
}

body {
  background-color: #eee;
  padding: 0;
  margin: 0;
}

.ui.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115),
    0%,
    rgb(33, 138, 174),
    69%,
    rgb(32, 167, 172) 89%
  );
}

.ui.main.container {
  margin-top: 7em;
}

.masthead {
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: #007c64;
  height: 100vh;
}

.masthead h1.ui.header {
  font-size: 4em;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-calendar {
  width: 100%;
  border: 0;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}

/* .active {
  text-decoration: underline;
} */
/* a:hover {
  color: #a2d2ff;
  text-decoration: none;
} */

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #007c64;
}

::selection {
  color: white;
  background: #007c64;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.auto-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 1rem;
}
.auto-grid-s {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-s-size), 1fr)
  );
  grid-gap: 1rem;
}

::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

/* Works on Firefox */
* {
  --main-color: #008e76;
  --secondary-color: white;

  scrollbar-width: thin;
  scrollbar-color: var(--main-color) var(--secondary-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--secondary-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 20px;
  border: 3px solid var(--secondary-color);
}

@import url("https://fonts.googleapis.com/css?family=Work+Sans:regular,bold,italic&subset=latin,latin-ext");
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  .btn {
    @apply px-4 py-2 text-white bg-gray-600 rounded-md;
  }
}

.dark {
  --color-bg-primary: #008e76;
  --color-bg-secondary: #283141;
  --color-text-primary: #f7fafc;
  --color-text-secondary: #e2e8f0;
  --color-text-accent: #81e6d9;
}

.light {
  --color-bg-primary: #ffffff;
  --color-bg-secondary: #edf2f7;
  --color-text-primary: #2d3748;
  --color-text-secondary: #4a5568;
  --color-text-accent: #2b6cb0;
}

* {
  outline: none !important;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(45deg, #007c64 10%, #b18ebd 90%);
  background-repeat: no-repeat;
  background-size: cover;
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #007c64;
}

::selection {
  color: white;
  background: #007c64;
}

.bg-main {
  background-color: var(--main-color);
}
.text-main {
  color: var(--main-color);
}

.price::before {
  content: "$ ";
}

.customFlex > div.rah-input-wrapper {
  flex-grow: 1;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
